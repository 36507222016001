import React, { useState, createContext, useContext } from "react";

const TodosContext = createContext();
export const useTodosContext = () => useContext(TodosContext);
export const TodosProvider = ({ children }) => {
  const [todos, setTodos] = useState([]);
  const [error, setError] = useState();
  const [selection, setSelection] = useState(0);
  const [listName, setListName] = useState("");

  return (
    <TodosContext.Provider
      value={{
        todos,
        setTodos,
        error,
        setError,
        selection,
        setSelection,
        listName,
        setListName,
      }}
    >
      {children}
    </TodosContext.Provider>
  );
};
