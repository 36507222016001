const Footer = () => {
  return (
    <div className="mt-auto pt-3">
      <p className="text-center">
        {" "}
        <a href="/about" className="link-primary mx-2">
          About
        </a>
        <a href="/privacy" className="link-primary mx-2">
          Privacy
        </a>
        <a href="/contact" className="link-primary mx-2">
          Contact
        </a>
      </p>
    </div>
  );
};

export default Footer;
