import { useTodosContext } from "../Context";
import { io } from "socket.io-client";
import { useLocation, useNavigate } from "react-router-dom";
import { validate as uuidValidate } from "uuid";
import { useCallback, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { isBrowser } from "react-device-detect";
import Todo from "./Todo";
import Error from "./Error";
// import Instructions from "./Instructions";
import FilterBar from "./FilterBar";

const server = process.env.REACT_APP_SERVER;
export const socket = io(server);

const Todos = () => {
  const { todos, setTodos, error, setError, selection } = useTodosContext();
  const navigate = useNavigate();
  const location = useLocation();

  // if user loads an existing list - emit message to server with the id
  let loadID;
  useEffect(() => {
    if (location.pathname !== "/") {
      const id = location.pathname.replace("/", "");
      if (uuidValidate(id)) {
        loadID = id;
        socket.emit("load-id", id);
      } else {
        setTodos([]);
        setError("Link is not valid. Redirecting to front page.");
      }
    }
  }, []);

  // if user loads an existing list - get todos from server if id exists and join id channel
  useEffect(() => {
    socket.on("load-id-todos", (loadTodos) => {
      if (loadTodos) {
        setTodos(loadTodos);
        socket.emit("id", loadID);
      } else {
        setTodos([]);
        setError("Link is not valid. Redirecting to front page.");
      }
    });
  }, []);

  // todos changed, the server needs to know

  socket.on("new-todos-changed", (todosChanged) => {
    setTodos(todosChanged);
  });

  socket.on("changed-todos-broadast", (newTodos) => {
    setTodos(newTodos);
  });

  const addTodos = (inputTodos) => {
    // if path is "/", I need to create a new list
    if (location.pathname === "/") {
      socket.emit("new-list");
      socket.on("new-list-created", (id) => {
        socket.emit("id", id);
        writeTodos(inputTodos);
        navigate("/" + id);
      });
    } else {
      // path is not "/", I should already be joined to a channel, I can emit "new-todos"
      writeTodos(inputTodos);
    }
  };

  const writeTodos = (inputTodos) => {
    if (inputTodos.trim() !== "") {
      socket.emit("new-todos", inputTodos);
    }
  };

  const addTodosChange = (e) => {
    if (e.which === 13 && !e.shiftKey) {
      const inputTodos = e.target.value;
      addTodos(inputTodos);
      e.target.value = "";
    }
  };

  const cleanUpTodosChange = (e) => {
    if (e.target.value === "\n") e.target.value = "";
  };

  // const moveListItem = () => {
  //   console.log("list item moved");
  // };
  const moveListItem = useCallback(
    (dragIndex, hoverIndex) => {
      const reversedTodos = [...todos].reverse();
      const dragItem = reversedTodos[dragIndex];
      const hoverItem = reversedTodos[hoverIndex];
      //console.log(dragItem, hoverItem, reversedTodos);
      const updatedTodos = [...todos].reverse();
      updatedTodos[dragIndex] = hoverItem;
      updatedTodos[hoverIndex] = dragItem;
      //console.log(updatedTodos);
      setTodos(updatedTodos.reverse());
      socket.emit("changed-todos", updatedTodos);
      //console.log("list item moved");
    },
    [todos]
  );

  if (error) {
    return <Error message={error} />;
  } else {
    return (
      <>
        <div className="row p-0 m-0">
          <div className="col-1 p-0 m-0 bg-white height4rem d-flex align-items-center justify-content-center">
            <div
              className="check-all"
              onClick={() => {
                console.log(document.getElementById("inputTextarea").value);
                addTodos(document.getElementById("inputTextarea").value);
                document.getElementById("inputTextarea").value = "";
              }}
            >
              +
            </div>
          </div>
          <div className="col-11 p-0 m-0">
            <textarea
              id="inputTextarea"
              autoFocus
              className=""
              placeholder="What to do?"
              onKeyDown={addTodosChange}
              onKeyUp={cleanUpTodosChange}
            />
          </div>
        </div>

        {/* if this is a new list or the list has zero items, then show the instructions */}
        {/* {todos.length === 0 && <Instructions />} */}

        {window.location.pathname !== "/" && <FilterBar />}

        <DndProvider backend={isBrowser ? HTML5Backend : TouchBackend}>
          {todos
            .slice()
            .reverse()
            .map((todo, index) => {
              return (
                <Todo
                  key={todo.id}
                  index={index}
                  moveListItem={moveListItem}
                  todo={todo}
                />
              );
            })}
        </DndProvider>
      </>
    );
  }
};

export default Todos;
