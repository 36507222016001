import { Check2Circle } from "react-bootstrap-icons";
import { XLg } from "react-bootstrap-icons";
import { useTodosContext } from "../Context";
import { socket } from "./Todos";
import { useDrag, useDrop } from "react-dnd";
import { useRef } from "react";

const Todo = ({ todo, index, moveListItem }) => {
  const { todos, setTodos, selection } = useTodosContext();

  const changeTodoStatus = () => {
    const newTodos = todos.map((stateTodo) => {
      if (stateTodo.id === todo.id) {
        return { ...stateTodo, status: stateTodo.status ? false : true };
      } else {
        return stateTodo;
      }
    });
    setTodos(newTodos); // set state
    socket.emit("changed-todos", newTodos); //send to server
  };

  const deleteTodo = () => {
    const newTodos = todos.filter((stateTodo) => {
      return stateTodo.id !== todo.id;
    });
    setTodos(newTodos); // set state
    socket.emit("changed-todos", newTodos); //send to server
  };

  const changeTodo = (e) => {
    const newTodos = todos.map((stateTodo) => {
      if (stateTodo.id === todo.id) {
        return { ...stateTodo, todo: e.target.value };
      } else {
        return stateTodo;
      }
    });
    setTodos(newTodos); // set state
    socket.emit("changed-todos", newTodos); //send to server
  };

  // useDrag - the list item is draggable
  const [{ isDragging }, dragRef] = useDrag({
    type: "item",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // useDrop - the list item is also a drop area
  const [spec, dropRef] = useDrop({
    accept: "item",
    hover: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;
      if (dragIndex === hoverIndex) return;

      console.log(dragIndex, hoverIndex);
      moveListItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  // Join the 2 refs together into one (both draggable and can be dropped on)
  const ref = useRef(null);
  const dragDropRef = dragRef(dropRef(ref));

  const opacity = isDragging ? 0.2 : 1;

  let classTodo = "row todo-row m-0 p-0 ";
  if (selection === 1 && todo.status) {
    classTodo += "d-none";
  }
  if (selection === 2 && !todo.status) {
    classTodo += "d-none";
  }

  return (
    <div ref={dragDropRef} className={classTodo} style={{ opacity }}>
      <div className="col-1 d-flex align-items-center justify-content-center">
        <span className="check-one" onClick={changeTodoStatus}>
          <Check2Circle fontSize={"1.5em"} />
        </span>
      </div>
      <div className="col-10">
        <input
          className={todo.status ? "todo-completed todo-input" : "todo-input"}
          value={todo.todo}
          placeholder="this is empty..."
          onChange={changeTodo}
        ></input>
      </div>
      <div className="col-1 d-flex align-items-center justify-content-center">
        <span className="delete-one" onClick={deleteTodo}>
          <XLg fontSize={"1.5em"} />
        </span>
      </div>
    </div>
  );
};

export default Todo;
