const Privacy = () => {
  return (
    <>
      <h1>Privacy</h1>
      {/* prettier-ignore */}
      <p>Guess what? No cookies! As in <a href="https://en.wikipedia.org/wiki/HTTP_cookie" target="_blank" rel="noreferrer">HTTP cookies</a>. Well, mostly. Because we're using Cloudflare as a CDN, they deploy essential cookies for the service to be provided. Read about it <a href="https://en.wikipedia.org/wiki/HTTP_cookie" target="_blank" rel="noreferrer">here</a>. We're also using Google Forms if you want to contact us, more about it <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">here</a>.</p>
      {/* prettier-ignore */}
      <p>Other than that, the only personal data we process is your IP address. And that's because of how Internet works. We remove all IP data after 30 days.</p>
      {/* prettier-ignore */}
      <p>One last thing, shared responsibility. This is an open platform with no authentication. Everything you type in is stored and everyone you share it with will see and be able to modify it.</p>
    </>
  );
};

export default Privacy;
