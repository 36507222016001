import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Application from "./Application";
import { TodosProvider } from "./Context";
import "./Application.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <TodosProvider>
        <Application />
      </TodosProvider>
    </BrowserRouter>
  </React.StrictMode>
);
