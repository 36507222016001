const About = () => {
  return (
    <>
      <h1>About</h1>
      {/* prettier-ignore */}
      <p> todoEZ is like if your most basic todo list app and <a href="https://pastebin.com/" target="_blank" rel="noreferrer">pastebin</a> had a child. A love child that is.</p>
      <h2>"Features"</h2>
      {/* prettier-ignore */}
      <ul>
        <li>Paste in a list of todo items and todoEZ will create one line for each item.</li>
        <li>You get a unique link for each list which you can share with others to collaborate.</li>
        <li>No logins, no accounts, just straight to business.</li>
        <li>Everyone sees updates in real time.</li>
      </ul>
    </>
  );
};

export default About;
