import { Clipboard, Whatsapp } from "react-bootstrap-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const ShareModal = () => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Copied to clipboard
    </Tooltip>
  );

  const clipboardClick = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-link btn-sm m-0 p-1 share-button"
        data-bs-toggle="modal"
        data-bs-target="#shareModal"
      >
        Share
      </button>

      <div
        className="modal fade"
        id="shareModal"
        tabIndex="-1"
        aria-labelledby="shareModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-3" id="shareModalLabel">
                Share this list
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row justify-content-center">
                <div className="col text-center pt-3">
                  <OverlayTrigger
                    // placement="right"
                    // delay={{ show: 250, hide: 400 }}
                    trigger={["click"]}
                    overlay={renderTooltip}
                    rootClose
                  >
                    <Clipboard
                      size={50}
                      onClick={clipboardClick}
                      style={{ cursor: "pointer" }}
                    />
                  </OverlayTrigger>

                  <p className="mt-3">Copy to clipboard</p>
                </div>
                <div className="col text-center pt-3">
                  <a
                    href={
                      "https://wa.me/?text=" + encodeURI(window.location.href)
                    }
                    data-action="share/whatsapp/share"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Whatsapp
                      size={50}
                      color="#25D366"
                      style={{ cursor: "pointer" }}
                    />
                  </a>

                  <p className="mt-3">Share on WhatsApp</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareModal;
