import { useTodosContext } from "../Context";
import { useEffect } from "react";
import ShareModal from "./ShareModal";
import { socket } from "./Todos";
import { useLocation } from "react-router-dom";

const FilterBar = () => {
  const { selection, setSelection, listName, setListName } = useTodosContext(); // 0 = all:default; 1 = in progress; 2 = completed
  const location = useLocation();

  const handleClick = (e) => {
    const clickSelector = Number(e.target.getAttribute("id"));
    if (clickSelector >= 0 && clickSelector <= 2) {
      setSelection(clickSelector);
    }
  };

  const listNameChange = (e) => {
    setListName(e.target.value);
    document.title = e.target.value + " - todoEZ";
    socket.emit("list-name-change-emit", e.target.value);
    //console.log("emmited new list name", e.target.value);
  };
  socket.on("list-name-change-receive", (newListName) => {
    //console.log("got new list name", newListName);
    setListName(newListName);
    document.title = newListName + " - todoEZ";
  });

  useEffect(() => {
    socket.on("connect", () => {
      socket.on("id-loaded", () => {
        socket.emit("list-name-get", location.pathname.slice(1));
        //console.log(location.pathname.slice(1), socket);

        socket.on("list-name-receive", (loadedListName) => {
          //console.log("list-name-receive ", loadedListName);
          setListName(loadedListName);
          document.title = loadedListName + " - todoEZ";
          socket.off("list-name-receive");
        });
      });
    });
  }, []);

  return (
    <div className="row p-0 m-0 filter-bar">
      <div className="col-5 small-top-padding ps-1">
        <input
          type="text"
          className="list-name"
          placeholder="List name"
          onChange={listNameChange}
          value={listName}
        />
      </div>
      <div className="col-6 m-0 p-0 small-top-padding">
        <span
          id="0"
          style={{ cursor: "pointer" }}
          className={
            selection === 0 ? "badge text-bg-secondary" : "badge text-bg-light"
          }
          onClick={handleClick}
        >
          All
        </span>
        <span
          id="1"
          style={{ cursor: "pointer" }}
          className={
            selection === 1 ? "badge text-bg-secondary" : "badge text-bg-light"
          }
          onClick={handleClick}
        >
          In progress
        </span>
        <span
          id="2"
          style={{ cursor: "pointer" }}
          className={
            selection === 2 ? "badge text-bg-secondary" : "badge text-bg-light"
          }
          onClick={handleClick}
        >
          Completed
        </span>
      </div>
      <div className="col-1 d-flex justify-content-end m-0 p-0">
        <ShareModal />
      </div>
    </div>
  );
};

export default FilterBar;
