import { Routes, Route } from "react-router-dom";
import Todos from "./Components/Todos";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Pages/About";
import Privacy from "./Pages/Privacy";
import Contact from "./Pages/Contact";

const Application = () => {
  return (
    <>
      <Routes>
        <Route
          path="/about"
          element={
            <>
              <Header />
              <div className="col-xxl-4 col-xl-5 col-lg-7 col-md-8 col-sm-10 col-11 mx-auto px-3 py-2 bg-white shadow">
                <About />
              </div>
              <Footer />
            </>
          }
        />
        <Route
          path="/privacy"
          element={
            <>
              <Header />
              <div className="col-xxl-4 col-xl-5 col-lg-7 col-md-8 col-sm-10 col-11 mx-auto px-3 py-2 bg-white shadow">
                <Privacy />
              </div>
              <Footer />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <Header />
              <div className="col-xxl-4 col-xl-5 col-lg-7 col-md-8 col-sm-10 col-11 mx-auto px-0 py-2 bg-white shadow">
                <Contact />
              </div>
              <Footer />
            </>
          }
        />
        <Route
          path="/"
          element={
            <>
              <Header />
              <div className="col-xxl-4 col-xl-5 col-lg-7 col-md-8 col-sm-10 col-11 mx-auto p-0 m-0 bg-white shadow">
                <Todos />
              </div>
              <Footer />
            </>
          }
        >
          <Route
            path=":id"
            element={
              <>
                <Header />
                <div className="col-xxl-4 col-xl-5 col-lg-7 col-md-8 col-sm-10 col-11 mx-auto p-0 m-0 bg-white shadow">
                  <Todos />
                </div>
                <Footer />
              </>
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default Application;
