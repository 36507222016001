import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTodosContext } from "../Context";

const Error = ({ message }) => {
  const { setError } = useTodosContext();
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      setError();
      navigate("/");
    }, 2000);
  });
  return (
    <>
      <div className="alert alert-danger text-center fw-bold">{message}</div>
    </>
  );
};

export default Error;
