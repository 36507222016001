const Contact = () => {
  return (
    <>
      <p align="center">
        <iframe
          title="Contact Form"
          src="https://docs.google.com/forms/d/e/1FAIpQLScV_MvfQ7sNCvNyi_9PYccfdnzXcEhrgINaqk4YbHJZRznUFg/viewform?embedded=true"
          width="360"
          height="930"
        >
          Loading…
        </iframe>
      </p>
    </>
  );
};

export default Contact;
